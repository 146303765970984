export default {
	camera: 0,
	// blending: 1,
	// autospin: 1,
	// transparent: 0,
	// autostart: 0,
	// preload: 0,

	animation_autoplay: 0,
	autospin: 0,
	autostart: 1,
	preload: 0,
	annotation: 0,
	annotation_cycle: 20,
	transparent: 1,
	// ui controls
	ui_controls: 0,
	ui_fullscreen: 0,
	ui_general_controls: 0,
	ui_help: 0,
	ui_infos: 0,
	ui_inspector: 0,
	ui_loading: 1,
	ui_settings: 0,
	ui_watermark: 0,
	ui_stop: 0,
	annotaion_autopilot: 0,
	ui_annotations: 1,
};
