<template>
	<div class="page">
		<div class="navbar"></div>
		<div class="main">
			<ModelFrame
				ref="frame"
				@error="showError"></ModelFrame>

			<!-- todo: to be fill from the back -->
			<div class="info-card">
				<div class="text">
					<div class="name">Product name</div>
					<div class="description">
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum modi saepe fugiat
						dolorem veniam provident quia? Nobis eaque temporibus recusandae beatae incidunt,
						veritatis, culpa accusamus assumenda quia magnam tempore necessitatibus!
					</div>
				</div>
				<div class="divider"></div>
				<div class="right">
					<div class="size">
						<div class="title">size</div>
						<div class="size-list">
							<div class="size-item">20x30</div>
							<div class="size-item">30x40</div>
							<div class="size-item">40x50</div>
						</div>
					</div>
					<div class="price">1000$</div>
					<div
						class="add-to-card button"
						@click="">
						add to cart
					</div>
				</div>
			</div>
			<!-- todo: end -->
		</div>
	</div>
</template>

<script>
import ModelFrame from '@/views/ModelFrame.vue';
import nodeConfig from '@/mocks/nodeConfig.json';
import materialConfig from '@/mocks/materialConfig.json';


export default {
	components: {
		ModelFrame,
	},

	methods: {
		showError(err) {
			console.log(err);
		},
	},

	mounted() {

		// const modelId = '3d04f8d8bcf24d649d951ad13b7ff85c'; // original
		const modelId = '8d1c8d4cbe354f64ba2fd92a23e7e0e6'; // test

		this.$refs.frame.startTheFrame(modelId, nodeConfig, materialConfig);

	},
};
</script>

<style lang="scss">
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
body {
	background-color: #f2f2f2;
}
.page {
	display: flex;
	.navbar {
		width: 300px;
		background-color: #ccc;
		height: 100vh;
		@media (max-width: 1000px) {
			width: 0;
		}
	}
	.main {
		flex: 1;
		padding: 1rem;
	}
}

.info-card {
	border-radius: 1rem;
	box-shadow: 0 0 1rem #0003;
	background-color: white;
	padding: 1rem 2rem;
	display: flex;
	@media (max-width: 768px) {
		flex-direction: column;
	}
	.text {
		flex: 3;
		margin-bottom: 1rem;
		.name {
			font-weight: bold;
			font-size: 1.1rem;
			margin-bottom: 0.3rem;
		}
	}
	.divider {
		min-width: 1px;
		min-height: 100%;
		background-color: #aaa;
		margin: 0 1rem;
	}
	.right {
		max-width: 300px;
		min-width: 300px;
		.size {
			margin-bottom: 1.3rem;
			.title {
				font-size: 0.9rem;
			}
			.size-list {
				display: flex;
				justify-content: space-between;
				.size-item {
					cursor: pointer;
					flex: 1;
					height: 3rem;
					background-color: #ddd;
					border-radius: 5px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 5px;
					&:hover {
						background-color: #aaa;
						outline: 1px solid #333;
					}
				}
			}
		}
		.price {
			font-weight: bold;
			font-size: 2rem;
			text-align: center;
			margin-bottom: 0.5rem;
		}
		.add-to-cart {
			width: 100%;
		}
	}
}
.button {
	cursor: pointer;
	padding: 10px 1rem;
	background-color: #e65c00;
	border-radius: 5px;
	text-align: center;
	color: white;
	text-transform: uppercase;
	transition: 0.3s;
	&:hover {
		box-shadow: 0 3px 10px #00000091;
	}
}
</style>
